import Vue from "vue";
import axios from "axios";
import * as api from "./api";
import * as XLSX from "xlsx";
import { BILL_TEMPLATE_CONFIG } from "@/config/BranchConfig";
import _ from "lodash";

const ALL_CURRENCY_SYMBOL = {
  VND: "",
  USD: "$",
  AUD: "$",
  EUR: "€",
  JPY: "¥",
};

const downscaleImage = (file) => {
  return new Promise((resolve) => {
    var canvas = document.createElement("canvas");

    var img = new Image();
    img.src = URL.createObjectURL(file);

    var fileName = file.name.split(".")[0];
    canvas.width = Math.min(img.width, 360);
    canvas.height = img.height * (canvas.width / img.width);
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    canvas.toBlob(function (blob) {
      var f2 = new File([blob], fileName + ".jpeg");
      resolve(f2);
    }, "image/jpeg");

    resolve(file);
  });
};

export const uploadFile = async (file) => {
  let url = process.env.VUE_APP_UPLOAD_API;
  let formData = new FormData();
  // var f = await downscaleImage(file)

  // resize
  formData.append("file", file);
  return new Promise((resolve) => {
    axios.post(`${url}/uploadFile`, formData).then((res) => {
      resolve(res.data);
    });
  });
};
export function downloadFile(url, name, params, method) {
  const headers = {
    "Content-Type": "application/xlsx",
    Authorization: `Bearer ${
      localStorage.getItem("token") ? localStorage.getItem("token") : ""
    }`,
  };
  axios({
    url: process.env.VUE_APP_RES_API + url,
    method: method ? method : "GET",
    responseType: "blob", // important
    headers,
    params,
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name || "import.xlsx");
    document.body.appendChild(link);
    link.click();
  });
}
export function downloadTemplateExcel(config = {}) {
  const formatDate = Vue.prototype.$formatDate;
  let wb = XLSX.utils.book_new();
  wb.Props = {
    Title: config.title || "Update Multi Product",
    Subject: config.subject || "Template Update",
    Author: "GratiSoft",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push(config.sheet_name || "Sample Template");
  let ws_headers = XLSX.utils.aoa_to_sheet([config.headers] || []);
  wb.Sheets["Sample Template"] = ws_headers;

  let wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i);
    return buf;
  }

  const url = window.URL.createObjectURL(new Blob([s2ab(wbout)]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${formatDate()}-template-update-multi.xlsx`);
  document.body.appendChild(link);
  link.click();
}
export function timeToString(time) {
  const d = new Date(time);
  const hr = d.getHours();
  let min = d.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  const date = d.getDate();
  let month = d.getMonth();
  if (month < 10) {
    month = "0" + month;
  }
  const year = d.getFullYear();
  const out = hr + ":" + min + " " + date + "/" + month + "/" + year;
  return out;
}

export const formatProduct = (productsRaw, priceLevelId) => {
  let branch = localStorage.getItem("branch");
  branch = branch ? JSON.parse(branch) : {};
  const branchId = branch.id;

  return productsRaw.map((itemRaw) => {
    var item = JSON.parse(JSON.stringify(itemRaw));
    const priceObj = item.productPrices.find(
      (i) => i.branches.id == branchId && i.priceLevel.id == priceLevelId
    );

    item.price = priceObj ? priceObj.price : itemRaw.priceDefault || 0;
    item.costPrice =
      item.productCostPrices && item.productCostPrices[0]
        ? item.productCostPrices[0].costPrice
        : 0;
    item.rawPrice = item.price;

    const productSales = (item.productSales || []).filter(
      (i) => i.branches.id == branchId
    );
    if (productSales && productSales.length) {
      const sale = productSales[0];
      if (sale.percentSaleOff) {
        item.saleOffPercent = sale.percentSaleOff;
        item.saleOffValue = Math.round(
          (item.price * sale.percentSaleOff) / 100
        );
        item.price = item.price - item.saleOffValue;
      } else {
        if (sale.cashSaleOff > item.price) {
          sale.cashSaleOff = item.price;
        }
        item.price = item.price - sale.cashSaleOff;
        item.saleOffValue = sale.cashSaleOff;
      }
      item.price = Math.round(item.price);
    }
    const productStatus = (item.productStatuses || []).find(
      (i) => i.branches.id == branchId
    );
    item.availableQuantity = 0;
    if (productStatus) {
      item.availableQuantity = productStatus.quantity;
    }
    if (!item.image) {
      item.image = "/images/no-image.jpg";
    }
    if (item.productDetails && item.productDetails.length) {
      item.productDetails = formatProduct(
        item.productDetails.map((i) => {
          i.detail.bundleQuantity = i.quantity;
          return i.detail;
        }),
        priceLevelId
      );
    }

    return item;
  });
};

export const resetData = (dataSend, cb) => {
  return new Promise((resolve) => {
    var i = 1;
    const onDone = () => {
      cb && cb(i);
      if (i++ >= 14) {
        Vue.prototype.$companyId = localStorage.getItem("companyId");
        Vue.prototype.$branchId = localStorage.getItem("branchId");
        Vue.prototype.$lang = localStorage.getItem("lang");
        Vue.prototype.$surchargeTypes = localStorage.getItem("surchargeTypes")
          ? localStorage.getItem("surchargeTypes").split(",")
          : [];

        Vue.prototype.$branch = JSON.parse(localStorage.getItem("branch"));
        Vue.prototype.$isSupperAdmin = JSON.parse(
          localStorage.getItem("isSupperAdmin")
        );

        Vue.prototype.$stocks = Vue.prototype.$branch.stocks;
        Vue.prototype.$stockDefault =
          Vue.prototype.$stocks.find((i) => i.isDefault) || {};
        Vue.prototype.$cardTypes = JSON.parse(
          localStorage.getItem("cardTypes")
        );
        Vue.prototype.$cards = JSON.parse(localStorage.getItem("cards"));
        Vue.prototype.$exchangeList = JSON.parse(
          localStorage.getItem("exchangeList")
        );
        Vue.prototype.$financeAuto = JSON.parse(
          localStorage.getItem("financeAuto")
        );
        Vue.prototype.$branchList = JSON.parse(
          localStorage.getItem("branchList")
        );
        Vue.prototype.$user = JSON.parse(localStorage.getItem("user"));
        Vue.prototype.$members = JSON.parse(localStorage.getItem("members"));
        Vue.prototype.$categories = JSON.parse(
          localStorage.getItem("categories")
        );
        Vue.prototype.$units = JSON.parse(localStorage.getItem("units"));
        Vue.prototype.$channels = JSON.parse(localStorage.getItem("channels"));
        Vue.prototype.$printTemplates = JSON.parse(
          localStorage.getItem("printTemplates")
        );
        Vue.prototype.$billTemplates = JSON.parse(
          // localStorage.getItem("printTemplates")
          localStorage.getItem("billTemplates")
        );

        Vue.prototype.$suppliers = JSON.parse(
          localStorage.getItem("suppliers")
        );
        Vue.prototype.$customerCompanies = JSON.parse(
          localStorage.getItem("customerCompanies")
        );

        Vue.prototype.$accountList = JSON.parse(
          localStorage.getItem("accountList")
        );

        Vue.prototype.$priceLevels = JSON.parse(
          localStorage.getItem("priceLevels")
        );
        if (Vue.prototype.$priceLevels.find((i) => i.isDefault)) {
          Vue.prototype.$priceLevelId = Vue.prototype.$priceLevels.find(
            (i) => i.isDefault
          ).id;
        }

        Vue.prototype.$company = JSON.parse(localStorage.getItem("company"));

        Vue.prototype.$financeCodes = JSON.parse(
          localStorage.getItem("financeCodes")
        );

        Vue.prototype.$treeCategories = JSON.parse(
          localStorage.getItem("treeCategories")
        );

        var paymentTypes = [
          { id: "CASH", name: "Tiền mặt" },
          { id: "DEBIT", name: "Dư nợ khách hàng" },
        ];
        Vue.prototype.$cardTypes.map((cardType) => {
          Vue.prototype.$cards.map((card) => {
            paymentTypes.push({
              id: cardType.typeName + "&&" + card.cardNumber,
              name: cardType.typeName + " (" + card.cardNumber + ")",
              isDirect: cardType.isDirect,
            });
          });
        });
        Vue.prototype.$paymentTypes = paymentTypes;

        resolve(true);
      }
    };

    api.findBranch().then((branch) => {
      onDone();

      const branchData = branch.data.data;
      branchData.appConfigs.map((i) => {
        var v = i.keyValues;
        if (v == "true") {
          v = true;
        }
        if (v == "false") {
          v = false;
        }
        branchData[i.keyName] = v;
      });
      branchData.percentTax = branchData.taxPercent;
      branchData.stocks = branchData.stocks.filter((a) => !a.isMaintained);
      var bills = [];
      if (Array.isArray(JSON.parse(branchData.billData))) {
        bills = JSON.parse(branchData.billData);
      }

      var billTemplates = bills.map((a) => ({
        ...a,
        name: a.name || a.key,
        type: a.type || a.key.split("-")[0],
        templateType: a.type || a.key.split("-")[0],
        templateSize: a.name || a.key,
        templateItem: a.billData, 
        template: a.content
      }));

      if (!billTemplates || !billTemplates.length) {
        billTemplates = JSON.parse(
          JSON.stringify(BILL_TEMPLATE_CONFIG.bills)
        ).map((b) => {
          var s = bills.find((i) => i.key == b.key);
          if (s) {
            b.content = s.content;
            b.css = s.css;
            b.billData = s.billData;
            b.name = s.name;
            b.type = s.key.split("-")[0];
          }
          return JSON.parse(JSON.stringify(b));
        });
      }

      const company = branchData.companies;
      company.appConfigs.map((i) => {
        var v = i.keyValues;
        if (v == "true") {
          v = true;
        }
        if (v == "false") {
          v = false;
        }
        company[i.keyName] = v;
      });
      localStorage.setItem("surchargeTypes", branchData.surchargeTypes || "");
      localStorage.setItem("billTemplates", JSON.stringify(billTemplates));
      localStorage.setItem("branch", JSON.stringify(branchData));
      localStorage.setItem("company", JSON.stringify(company));
      localStorage.setItem("companyId", branchData.companies.id);
      localStorage.setItem("branchId", branchData.id);

      Vue.prototype.$currency = company.currency || "VND";
      Vue.prototype.$currencySymbol =
        ALL_CURRENCY_SYMBOL[Vue.prototype.$currency];
      Vue.prototype.$currencyDecimal = company.currencyDecimal || 1;

      localStorage.setItem("currency", company.currency);
      localStorage.setItem(
        "currencySymbol",
        ALL_CURRENCY_SYMBOL[company.currency]
      );
      localStorage.setItem("currencyDecimal", company.currencyDecimal || 1);

      api
        .getCurrentUser({
          "companies.id": branchData.companies.id,
        })
        .then((userData) => {
          onDone();
          let roles = [];
          userData.list.map((branch) => {
            localStorage.setItem("isSupperAdmin", branch.users.isSupperAdmin);
            branch.userBranchRoles.map((userBranchRole) => {
              var user = {
                ...branch.users.employees,
                ...branch.users,
                employeeId: branch.users.employees && branch.users.employees.id,
              };
              if (!branch.users.employees) {
                user = { fullName: branch.users.userName, ...branch.users };
              }
              localStorage.setItem("user", JSON.stringify(user));
              userBranchRole.roles.roleDetails.map((roleDetail) => {
                roles.push({
                  branchId: branch.branches.id,
                  rolesName: userBranchRole.roles.roleName,
                  ...roleDetail.pageFunction,
                  isUsed: roleDetail.isUsed,
                  fxCode: roleDetail.pageCode,
                });
              });
            });
          });
          roles = roles
            .filter((i) => i.isUsed)
            .filter((i) => i.fxCode)
            .filter((i) => +i.branchId == +branchData.id);
          localStorage.setItem("currentRoles", JSON.stringify(roles));
          localStorage.setItem("branchList", JSON.stringify(userData.list));
        });

      api
        .getExchangeRatesByBranch(branchData.id, {}, "id_desc", 100, 0)
        .then((exchangeList) => {
          exchangeList = exchangeList.list || [];
          if (
            !exchangeList.find((i) => i.currency == Vue.prototype.$currency)
          ) {
            exchangeList.push({
              currency: Vue.prototype.$currency,
              exchangePrice: 1,
            });
          }
          onDone();

          localStorage.setItem("exchangeList", JSON.stringify(exchangeList));
        });

      api.getCardsByBranch(branchData.id).then((cards) => {
        onDone();
        cards = cards.data.data || [];
        if (!cards.length) {
          cards = [
            {
              id: "Thẻ mặc định",
              cardNumber: "Thẻ mặc định",
            },
          ];
        }
        localStorage.setItem("cards", JSON.stringify(cards));
      });

      api.getCardTypes(branchData.id).then((cardTypes) => {
        onDone();
        cardTypes = cardTypes.list.map((i) => {
          return {
            id: i.typeName,
            typeName: i.typeName,
            isDirect: i.isDirect,
          };
        });
        if (!cardTypes.length) {
          cardTypes = [
            {
              id: "Thanh toán thẻ",
              typeName: "Thanh toán thẻ",
            },
          ];
        }
        localStorage.setItem("cardTypes", JSON.stringify(cardTypes));
      });

      api.findConfigAutoFinance(branchData.id).then((financeAuto) => {
        onDone();
        localStorage.setItem("financeAuto", JSON.stringify(financeAuto));
      });

      api
        .getMembershipRank(
          { "companies.id": branchData.companies.id },
          1000,
          0,
          "id_desc"
        )
        .then((members) => {
          onDone();
          localStorage.setItem("members", JSON.stringify(members.list));
        });

      api.getChannels(branchData.companies.id).then((channels) => {
        onDone();
        if (channels.length == 0) {
          channels.push({
            priority: 0,
            id: "Trực tiếp",
            name: "Trực tiếp",
          });
        }

        channels = _.sortBy(channels, "priority", "asc");
        localStorage.setItem("channels", JSON.stringify(channels));
      });

      api
        .getCustomerCompany(
          { companyId: branchData.companies.id },
          1000,
          0,
          "id_asc"
        )
        .then((customerCompanies) => {
          onDone();
          localStorage.setItem(
            "customerCompanies",
            JSON.stringify(
              customerCompanies.list.map((a) => ({
                ...a,
                name: a.shortName,
                search: a.code,
              }))
            )
          );
        });

      api
        .getSuppliers(
          { "companies.id": branchData.companies.id },
          1000,
          0,
          "id_asc"
        )
        .then((res) => {
          var suppliers = res
            .map((i) => ({
              ...i,
              name: `${i.supplierName} ${i.isInternal ? " (Nội bộ)" : ""}`,
              search: i.supplierCode,
            }))
            .filter(
              (i) =>
                !i.supplierBranches.length ||
                i.supplierBranches.find((i) => i.branches.id == branchData.id)
            );
          onDone();
          localStorage.setItem("suppliers", JSON.stringify(suppliers));
        });

      api
        .findPriceLevel(
          { companyId: branchData.companies.id },
          1000,
          0,
          "id_asc"
        )
        .then((res) => {
          var priceLevels = res.list;
          onDone();
          localStorage.setItem("priceLevels", JSON.stringify(priceLevels));
        });

      api
        .findBranchCircularCategory({ "branches.id": branchData.id })
        .then((res) => {
          var accountList = res
            .map((i) => {
              return {
                id: i.id,
                code: i.companyCircularCategory.code,
                title: i.companyCircularCategory.name,
                name:
                  i.companyCircularCategory.code +
                  " - " +
                  i.companyCircularCategory.name,
              };
            })
            .filter((a) => a.code)
            .sort((a, b) => (a.code > b.code ? 1 : -1));
          onDone();
          localStorage.setItem("accountList", JSON.stringify(accountList));
        });

      api
        .getProductUnits(
          { "companies.id": branchData.companies.id },
          1000,
          0,
          "id_desc"
        )
        .then((res) => {
          onDone();
          localStorage.setItem("units", JSON.stringify(res));
        });
      api
        .getSmallGroups(
          { "companies.id": branchData.companies.id },
          1000,
          0,
          "id_desc"
        )
        .then((categories) => {
          onDone();

          var smalls = categories.list.filter((small) => {
            small.applies = small.applies ? small.applies.split(",") : [];
            if (small.branches.length) {
              return small.branches.find((i) => i.branches.id == branchData.id);
            } else {
              return true;
            }
          });
          localStorage.setItem("categories", JSON.stringify(smalls));
        });

      api
        .getIncomeExpenseElement(
          { companyId: branchData.companies.id },
          1000,
          0,
          "id_desc"
        )
        .then((res) => {
          onDone();
          var financeCodes = res.list.map((a) => ({ ...a, id: a.code }));
          localStorage.setItem("financeCodes", JSON.stringify(financeCodes));
        });

      api
        .getPrintTemplate({ branchId: branchData.id }, 1000, 0, "id_desc")
        .then((res) => {
          onDone();
          var prints = res.list.map((a) => ({ ...a, id: a.code }));
          localStorage.setItem("printTemplates", JSON.stringify(prints));
        });

      api
        .getCategoriesTree(
          { "companies.id": branchData.companies.id },
          1000,
          0,
          "id_desc"
        )
        .then((res) => {
          onDone();
          var tree = res.list.map((a) => ({ ...a, id: a.code }));
          localStorage.setItem("treeCategories", JSON.stringify(tree));
        });

      // }).catch(e => {
      //   console.log(e.)
    });
  });
};
