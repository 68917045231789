<template>
  <section class="menu-sub">
    <div class="menu-list">
      <router-link
        tag="div"
        class="menu-item"
        v-for="(menu, index) in menus"
        :key="index"
        :to="menu.link"
        v-if="$permission(menu.permission)"
        :class="{ active: $route.path == menu.link }"
      >
        <div
          :class="'img'"
          :style="{ backgroundImage: 'url(' + menu.icon + ')' }"
        />
        <div class="title">
          <p>{{ menu.title }}</p>
        </div>
      </router-link>
    </div>
    <div class="data-outer">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          title: "Route",
          icon: "/images/shopping-list.png",
          permission: "ORDER:ALL",
          link: "/shipping/track",
        },
        {
          title: "Route",
          icon: "/images/shopping-list.png",
          permission: "ORDER:ALL",
          link: "/shipping/track",
        },
        {
          title: "Route",
          icon: "/images/shopping-list.png",
          permission: "ORDER:ALL",
          link: "/shipping/track",
        },
        
      ],
    };
  },
};
</script>
