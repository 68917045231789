<template>
  <div class="data-list" :class="{ 'file-space': isViewFile }">
    <div
      class="bulk-actions align-items-center flex flex-row"
      v-if="selectedList.length && bulkActions.length"
    >
      <p class="mr-3">{{ selectedList.length }} đã chọn</p>
      <b-dropdown text="Chọn chức năng" class="m-md-2" variant="white">
        <b-dropdown-item
          @click="action.click(selectedList)"
          :key="action.key"
          v-for="action in bulkActions"
        >
          <i :class="action.icon" />
          {{ action.name }}
        </b-dropdown-item>
      </b-dropdown>
      <div class="flex flex-1" />
      <button
        class="btn btn-basic text-white"
        @click="
          selecteds = {};
          onSelected();
        "
      >
        <i class="fa fa-times" />
        Huỷ
      </button>
    </div>
    <div class="data-grid d-block d-md-none">
      <div class="box p-2 mb-2" v-for="(row, index) in dataSource" :key="index">
        <div
          class="box-item flex flex-row align-items-center"
          :class="
            typeof field.className == 'function'
              ? field.className(row)
              : field.className
          "
          :style="{ width: row.width, minWidth: row.minWidth }"
          v-for="field in fieldsShow"
          :key="field.key ? field.key : field"
          @click="field.click && field.click(row)"
        >
          <div class="flex flex-1">
            <label
              class="text nobreak m-0"
              v-html="field.title || field.key || field"
            ></label>
          </div>
          <div class="text-right">
            <span v-if="field && field.link">
              <strong>
                <router-link
                  :to="field.link(row)"
                  v-html="
                    field.showData
                      ? field.showData(row)
                      : row[field.key ? field.key : field]
                  "
                ></router-link>
              </strong>
            </span>
            <span
              v-else
              v-html="
                field.showData
                  ? field.showData(row)
                  : row[field.key ? field.key : field]
              "
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="data-table d-none d-md-block"
      :style="{ height: height ? height : 'calc(100lvh - 140px)' }"
      @drop.prevent="addFile"
      @dragover.prevent="isViewFile = true"
      @dragenter="isViewFile = true"
      @dragleave="isViewFile = false"
      v-cloak
      :class="{
        'scroll-left': isScrollLeft,
        'scroll-right': isScrollRight,
      }"
      @scroll="onScrollTable"
      ref="scrollTableHorizon"
    >
      <table class="table">
        <thead>
          <tr>
            <th
              class="sticky"
              style="width: 40px; min-width: 40px"
              v-if="mode == 'select'"
            >
              <check-box v-model="isCheckAll" @change="onSelectAll()" />
            </th>

            <th
              :class="
                typeof field.className == 'function'
                  ? field.className()
                  : field.className
              "
              @click="!field.noSort && changeSort(field.key)"
              v-for="(field, index) in fieldsShow"
              :key="field.key + index"
              :style="{
                'min-width': field.minWidth || field.width,
                width: field.width,
              }"
              class="nobreak"
            >
              <span class="sort" v-if="!field.noSort">
                <i
                  class="fa fa-sort-up"
                  :class="{ active: sortKey == field.key && sortType == 'asc' }"
                />
                <i
                  class="fa fa-sort-down"
                  :class="{
                    active: sortKey == field.key && sortType == 'desc',
                  }"
                />
              </span>
              <span
                class="text"
                v-html="field.title || field.key || field"
              ></span>

              <p v-if="totals" class="text-right bold">
                {{ totals[field.key] }}
              </p>
            </th>
            <th
              class="action"
              v-if="rowActions && rowActions.length"
              style="width: 10px"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in dataSource"
            :key="index"
            :class="{ disabled: row._disabled }"
          >
            <td class="sticky" v-if="mode == 'select'">
              <check-box
                v-model="selecteds[row.id]"
                @change="onSelected()"
                :disabled="row._disabledCheck"
              />
            </td>

            <td
              :class="
                typeof field.className == 'function'
                  ? field.className(row)
                  : field.className
              "
              :style="{ width: row.width }"
              v-for="field in fieldsShow"
              :key="field.key + index"
              v-b-tooltip.hover
              :title="
                typeof field.tooltip == 'function'
                  ? field.tooltip(row)
                  : field.tooltip
              "
              @click="field.click && field.click(row)"
            >
              <span v-if="field.link">
                <strong>
                  <router-link
                    :to="field.link(row)"
                    v-html="
                      field.showData
                        ? field.showData(row)
                        : row[field.key || field]
                    "
                  ></router-link>
                </strong>
              </span>
              <span v-else-if="field.checkbox != null">
                <check-box :disabled="true" :value="field.checkbox(row)" />
              </span>
              <span
                v-else
                v-html="
                  field.showData ? field.showData(row) : row[field.key || field]
                "
              ></span>
            </td>
            <td
              class="action"
              v-if="mode == 'add' || (rowActions && rowActions.length)"
            >
              <span v-for="(action, index) in rowActions" :key="index">
                <button
                  v-b-tooltip.hover
                  :title="action.name"
                  v-if="!action.isShow || (action.isShow && action.isShow(row))"
                  @click="action.click(row)"
                  :class="'btn btn-sm btn-' + action.color"
                >
                  <i
                    :class="
                      action.isLoading && action.isLoading(row)
                        ? 'fa fa-spinner fa-pulse fa-spin'
                        : action.icon
                    "
                  />
                </button>
              </span>
              <!-- <button
                v-if="!row._noDelete && !noDelete"
                @click="removeOne(row, index)"
                class="btn btn-danger btn-sm nobreak"
              >
                <i class="mdi mdi-delete" />
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="!dataSource.length && !loading"
        class="flex align-items-center justify-content-center"
        :style="{
          height: mode == 'add' ? 'calc(100% - 130px)' : 'calc(100% - 80px)',
        }"
      >
        <!-- <img width="100px" class="mb-2" style="opacity:0.7" src="/images/box.png" /> -->
        <p class="p-2 m-0 text-center" v-if="mode == 'add'">
          Hãy nhập dữ liệu vào hệ thống
          <br />có thể nhập hoặc upload tệp lên.
          <br />
          <!-- <a class="link" @click="downloadSample('file_mau_' + name)">
            Tệp mẫu
            <i class="fa fa-arrow-down" />
          </a> -->
          <!-- | -->
          <a class="link" @click="chooseFile()">
            Upload
            <i class="fa fa-arrow-up" />
          </a>

          <input
            ref="file"
            type="file"
            accept=".xlsx, .xls"
            class="hide"
            @change="upload()"
          />
        </p>
        <p class="font-16 m-0 p-5 bold" v-if="mode != 'add'">
          <i class="mdi mdi-emoticon-sad" /> Không có dữ liệu
        </p>
      </div>
    </div>
    <div class="flex flex-row align-items-center list-footer" v-if="!noFooter">
      <div class="bold" v-if="mode == 'select'">
        {{ selectedList.length }} lựa chọn
      </div>
      <div class="flex flex-200px" v-if="!noPaging">
        <p class="left-footer font-12">
          <select v-model="limit" @change="changeLimit()">
            <option v-for="l in $allLimit" :key="l">{{ l }}</option>
          </select>
          {{
            $t("common.from_page_to_page", {
              from: (page - 1) * limit + 1,
              to: page * limit > total ? total : page * limit,
              of: total,
            })
          }}
        </p>
      </div>
      <div class="flex flex-1 align-items-center">
        <Pagination
          :total="totalPage"
          :currentPage="page"
          @changePage="onChangePage"
          v-if="!noPaging"
        />
      </div>
      <div
        class="flex flex-230px hide-sm"
        style="position: relative"
        v-if="!noSearch"
      >
        <input
          maxlength="200"
          class="form-control font-12"
          v-model="searchKey"
          :placeholder="$t('common.search')"
          @keyup.enter="getData()"
        />
        <i
          v-if="searchKey"
          class="fa fa-times remove-search pointer"
          @click="
            searchKey = '';
            search();
          "
        />
      </div>

      <b-dropdown
        size="sm"
        text="..."
        no-caret
        variant="basic"
        class="mx-2"
        right
      >
        <b-dropdown-item-button @click="exportAll()">
          Xuất ra .xlsx
        </b-dropdown-item-button>

        <b-dropdown-item-button @click="openConfig()">
          Cài đặt cột
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <div v-if="loading" class="loading">
      <div>
        <div class="loading-spin">
          <i />
        </div>
        <p>Đang tải</p>
      </div>
    </div>
    <data-table-config :tableName="name" ref="config" />
  </div>
</template>

<script>
import Pagination from "./Pagination";
import * as XLSX from "xlsx";
import { printTableExcel } from "@/services/print";
import { scrollMixin } from "@/services/mixins";
import DataTableConfig from "./DataTableConfig.vue";
export default {
  components: {
    Pagination,
    DataTableConfig,
  },
  mixins: [scrollMixin],

  name: "DataTable",
  props: [
    "fields",
    "rowActions",
    "noSearch",
    "dataRaw",
    "height",
    "loading",
    "mode",
    "selectedRaw",
    "noPaging",
    "keySearch",
    "sortInit",
    "name",
    "bulkActions",
    "noDelete",
    "noFooter",
    "totals",
  ],
  data() {
    return {
      isViewFile: false,
      sortKey: "id",
      sortType: "desc",
      dataSource: [],
      page: 1,
      limit: 25,
      total: 0,
      totalPage: 1,
      searchKey: "",
      newRecord: {},
      list: [],
      selecteds: [],
      selectedList: [],
      isCheckAll: false,
      viewsConfig: {},
    };
  },
  created() {
    this.limit = localStorage.getItem("config_limit") || 25;
    if (this.sortInit) {
      this.sortKey = this.sortInit.split("_")[0];
      this.sortType = this.sortInit.split("_")[1];
    }
  },
  watch: {
    dataRaw() {
      this.list = JSON.parse(JSON.stringify(this.dataRaw || []));
      this.page = 1;
      this.getData();
    },
    selectedRaw() {
      this.selecteds = this.selectedRaw;
      this.onSelected();
    },
    fields() {
      this.fields.map((i) => {
        if (i._addValue) {
          this.newRecord[i.key] = i._addValue;
        }
      });
    },
  },
  computed: {
    fieldsShow() {
      return this.fields.filter(
        (i) =>
          (!i.noShow || (i.noShow && !i.noShow())) &&
          this.viewsConfig[i.key] !== false
      );
    },
  },
  mounted() {
    this.list = JSON.parse(JSON.stringify(this.dataRaw || []));
    this.page = 1;
    this.selecteds = this.selectedRaw;

    this.fields.map((i) => {
      if (i._addValue) {
        this.newRecord[i.key] = i._addValue;
      }
    });

    this.onSelected();
    this.getData();
  },
  methods: {
    openConfig() {
      this.$refs.config.open({ columns: this.fields }).then(() => {
        this.getConfig();
      });
    },
    getConfig() {
      const d = localStorage.getItem("config-" + this.name);
      if (d) {
        const data = JSON.parse(d);
        this.viewsConfig = data.views || {};
        this.widthsConfig = data.widths || {};
        this.$forceUpdate();
      }
    },

    exportAll() {
      let inputs = [
        { title: "Tên file", key: "filename", value: "", width: "100%" },
      ];

      const config = {
        title: "Xuất excel tất cả",
        size: "sm",
        allowClose: true,
      };
      this.$root.formPopup.sending = false;
      this.$root.formPopup.openPopup(inputs, config, async (form) => {
        this.$root.formPopup.closePopup();
        const list = this.list.concat(this.totals || {});
        printTableExcel(list, this.fields, form.fileName || "xuat-excel");
      });
    },

    chooseFile() {
      this.$refs.file.click();
    },
    addNew(data) {
      var newRecord = data || this.newRecord;
      var requireds = this.fields.filter((i) => i._addRequired);
      var messRequire = requireds
        .map((i) => {
          if (!newRecord[i.key]) {
            return i.title || i.key;
          }
        })
        .filter((i) => i);
      if (messRequire.length) {
        return this.$noti.open(
          messRequire.join(", ") + " cần điền đầy đủ",
          true
        );
      }
      var uniques = this.fields.filter((i) => i._addUnique);

      var messUnique = uniques
        .map((i) => {
          if (this.list.find((r) => r[i.key] == newRecord[i.key])) {
            return i.title || i.key;
          }
        })
        .filter((i) => i);
      if (messUnique.length) {
        return this.$noti.open(
          messUnique.join(", ") + " không được trùng",
          true
        );
      }

      var messMax = this.fields
        .filter((i) => i._addMax > 0)
        .map((i) => {
          var d = newRecord[i.key] + "";
          if (d.length > i._addMax) {
            return (
              (i.title || i.key) + " không được quá " + i._addMax + " ký tự"
            );
          }
        })
        .filter((i) => i);
      if (messMax.length) {
        return this.$noti.open(messMax.join(", "), true);
      }

      var messMin = this.fields
        .filter((i) => i._addMin > 0)
        .map((i) => {
          var d = newRecord[i.key] + "";
          if (d.length < i._addMin) {
            return (i.title || i.key) + " phải hơn " + i._addMin + " ký tự";
          }
        })
        .filter((i) => i);
      if (messMin.length) {
        return this.$noti.open(messMin.join(", "), true);
      }

      var trims = this.fields.filter((i) => i._addTrim);
      trims.map((i) => {
        newRecord[i.key] = (newRecord[i.key] + "")
          .split(" ")
          .join("")
          .toUpperCase();
      });

      newRecord.id = new Date().getTime() + Math.random();
      this.list.push(newRecord);
      this.newRecord = {};
      this.fields.map((i) => {
        if (i._addValue) {
          this.newRecord[i.key] = i._addValue;
        }
      });
      this.getData();
    },
    removeOne(item) {
      this.list = this.list.filter((i) => i.id != item.id);
      this.getData();
    },
    reRender() {
      this.$forceUpdate();
    },
    onChangePage(page) {
      this.page = page;
      this.getData();
    },
    changeLimit() {
      localStorage.setItem("config_limit", this.limit);
      this.page = 1;
      this.getData();
    },
    getData() {
      this.getConfig();
      let list = JSON.parse(JSON.stringify(this.list || []));
      list.sort((a, b) => {
        let out = a[this.sortKey] > b[this.sortKey] ? 1 : -1;
        if (this.sortType == "desc") {
          out = out * -1;
        }
        return out;
      });

      let dataAll = list;
      if (this.searchKey) {
        dataAll = list.filter((item) => {
          let out = false;
          this.fields.map((col) => {
            if (!col.noSort) {
              let dataValue = col.showData ? col.showData(item) : item[col.key];
              out = out || this.$isInner(dataValue + "", this.searchKey);
            }
          });
          return out;
        });
      }
      var limit = this.noPaging ? 99999999 : this.limit;
      this.totalPage = Math.ceil(dataAll.length / limit);
      this.total = dataAll.length;

      this.dataSource = dataAll.splice((this.page - 1) * limit, limit);
      this.onSelected();
    },
    changeSort(key) {
      if (this.sortKey === key) {
        this.sortType = this.sortType === "desc" ? "asc" : "desc";
      } else {
        this.sortType = "desc";
      }
      this.sortKey = key;

      this.getData();
    },

    returnData() {
      return this.list.sort((a, b) => {
        let out = a[this.sortKey] > b[this.sortKey] ? 1 : -1;
        if (this.sortType == "desc") {
          out = out * -1;
        }
        return out;
      });
    },
    getSelected() {
      return this.selectedList;
    },
    upload(e) {
      this.isViewFile = false;
      const file = e || this.$refs.file.files[0];
      if (!file) {
        return;
      }
      const arr = (file.name || "").split(".");
      if (arr[arr.length - 1] !== "xls" && arr[arr.length - 1] !== "xlsx") {
        this.$noti.open("Chỉ chấp nhận file excel", true);
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let workbook = XLSX.read(data, {
          type: "binary",
        });
        const dataUpdates = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        const result = dataUpdates.map((i) => {
          const out = {};
          this.fields.map((f) => {
            out[f.key] = i[f.title];
          });
          return out;
        });
        if (!result.length) {
          this.$noti.open("Hãy nhập thông tin vào file excel", true);
        }
        result.map((i) => {
          this.addNew(i);
        });
      };
      reader.readAsBinaryString(file);
    },
    downloadSample(fileName) {
      printTableExcel(
        [],
        this.fields,
        fileName || "Sample",
        fileName || "Sample"
      );
    },
    onSelectAll() {
      if (this.isCheckAll) {
        this.dataSource
          .filter((i) => !i._disabledCheck)
          .forEach((i) => {
            this.selecteds[i.id] = true;
          });
      } else {
        this.dataSource
          .filter((i) => !i._disabledCheck)
          .forEach((i) => {
            this.selecteds[i.id] = false;
          });
      }
      this.onSelected();
    },
    onSelected() {
      const selecteds = {};
      const selectedList = [];
      Object.keys(this.selecteds || {}).map((i) => {
        if (this.selecteds[i]) {
          selecteds[i] = true;
          selectedList.push(i);
        }
      });
      this.selecteds = selecteds;
      this.selectedList = selectedList;
      this.isCheckAll = 2;
      var totalCheck = 0;
      totalCheck = this.dataSource.reduce(
        (a, b) => (this.selecteds[b.id] ? a + 1 : a),
        0
      );
      if (totalCheck == this.dataSource.length) {
        this.isCheckAll = 1;
      }
      if (totalCheck == 0) {
        this.isCheckAll = 0;
      }
    },
    clearSelectedList() {
      this.selectedList = [];
      this.isCheckAll = 0;
      this.selecteds = {};
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      this.upload(droppedFiles[0]);
    },
  },
};
</script>
