import _ from "lodash";
import Vue from "vue";

export const setStockAuto = (products, fulfilled) => {
  var isStockCompany = Vue.prototype.$branch.isStockCompany;
  products.filter((product) => {
    [product].concat(product.isBundle ? product.children : []).forEach((p) => {
      p.availableStocks.forEach((i) => {
        if (!i.batchName) {
          i.batchName = "-";
        }
      });
      p.stocks = p.availableStocks.mergeTotal("stockId", "availableQty");

      var bs = _.orderBy(
        p.availableStocks,
        [
          "isDefaultTmp",
          "expiryDateTmp",
          "manufacturingDateTmp",
          "inventoryDateTmp",
        ],
        ["desc", "asc", "asc", "asc"]
      );

      p.batchNames = bs;

      p.fulfills = [];
      p.remain = product.remain * (p.bundleQuantity || 1);
      if (fulfilled && fulfilled.length) {
        fulfilled
          .filter(
            (i) =>
              i.productId == p.productId &&
              i.bundleId == p.bundleId &&
              i.isGift == p.isGift
          )
          .map((f) => {
            var oldS = p.fulfills.find((i) => i.stockId == f.stockId);
            if (oldS) {
              oldS.batchNames.push({
                quantity: f.quantity,
                batchName: f.batchName,
              });
            } else {
              var bs = p.batchNames.filter((i) => i.stockId == f.stockId);
              p.fulfills.push({
                stockId: f.stockId,
                allBatchNames: bs,
                batchNames: [
                  {
                    quantity: f.quantity,
                    batchName: f.batchName == null ? "-" : f.batchName,
                  },
                ],
              });
            }
          });
      } else {
        p.remain = product.remain * (p.bundleQuantity || 1);
        var remain = p.remain;
        p.fulfills = [];
        bs.filter((a) => !a.isNotForSell).forEach((b) => {
          if (remain <= 0) {
            return;
          }
          var stock = p.fulfills.find((i) => i.stockId == b.stockId);
          var q = Math.min(remain, b.availableQty);

          remain = remain - q;
          if (q > 0) {
            if (stock) {
              stock.batchNames.push({
                quantity: q,
                batchName: b.batchName,
              });
            } else {
              p.fulfills.push({
                stockId: b.stockId,
                allBatchNames: bs.filter((i) => i.stockId == b.stockId),
                batchNames: [
                  {
                    quantity: q,
                    batchName: b.batchName,
                  },
                ],
              });
            }
          }
        });
        if (remain > 0 && (p.saleOver || !isStockCompany)) {
          if (p.fulfills[0]) {
            p.fulfills[0].batchNames[0].quantity += remain;
          } else {
            var s = bs[0] || {};
            p.fulfills = [
              {
                stockId: s.stockId,
                allBatchNames: bs.filter((i) => i.stockId == s.stockId),
                batchNames: [
                  {
                    quantity: remain,
                    batchName: s.batchName,
                  },
                ],
              },
            ];
          }
          remain = 0;
        }
      }

      if (p.fulfills.length == 0) {
        p.fulfills = [
          {
            stockId: "",
            allBatchNames: [],
            batchNames: [
              {
                quantity: 0,
                batchName: "-",
              },
            ],
          },
        ];
      }
      p.quantityInput = product.remain * (p.bundleQuantity || 1);
      p.fulfills.forEach((i) => {
        i.quantity = i.batchNames.reduce((a, b) => a + +b.quantity, 0);
      });
      p.totalFF = p.fulfills.reduce((a, b) => a + +b.quantity, 0);
      p.availableQty = p.availableStocks.reduce(
        (a, b) => a + +b.availableQty,
        0
      );

      if (
        p.remain > p.availableQty &&
        !p.isBundle &&
        !p.noCheckWarehouse &&
        !p.saleOver &&
        isStockCompany
      ) {
        p.missStock = true;
      }
      if (
        p.availableQty == 0 &&
        // p.totalFF == 0 &&
        !p.isBundle &&
        !p.noCheckWarehouse &&
        !p.saleOver &&
        isStockCompany
      ) {
        p.outOfStock = true;
      }
    });
  });
  return products;
};

export const countPriceB2B = (order) => {
  let total = 0;
  order.totalQuantity = 0;
  order.beforeSaleOffTotal = 0;
  order.saleOffTotal = 0;
  order.discountCustomerTotal = 0;
  order.surchargeProductTotal = 0;
  order.costTotal = 0;
  order.taxMoney = 0;
  order.totalAfterTaxProduct = 0;
  order.invoiceInitials = order.invoiceInitials || [];
  order.totalProductQuantity = 0;
  order.totalGiftQuantity = 0;
  order.priceLevelId = order.priceLevelId || Vue.prototype.$priceLevelId;
  order.branchId = order.branchId || Vue.prototype.$branchId;
  if (!order.sameTax) {
    order.percentTax = 0;
  }
  order.invoiceInitials
    .filter((i) => !i.isDelete)
    .map((item) => {
      if (item.productPrices && item.productPrices.length && !order.id) {
        var priceItem = item.productPrices.find(
          (a) =>
            a.branches.id == order.branchId &&
            a.priceLevel.id == order.priceLevelId
        );
        item.productPrice = priceItem ? priceItem.price : item.priceDefault;
      }
      if (item.isGift) {
        item.productPrice = 0;
      }
      item.costPrice = item.costPrice || 0;
      item.surchargeType = item.surchargeType || "MONEY";
      item.saleOffType = item.saleOffType || "MONEY";

      if (item.saleOffType == "PERCENT" && item.saleOffValue > 100) {
        item.saleOffValue = 100;
      }
      item.percentTax = item.percentTax || 0;
      item.surchargeValue = item.surchargeValue || 0;
      item.saleOffValue = item.saleOffValue || 0;
      if (Math.abs(item.saleOffValue) > Math.abs(item.productPrice)) {
        item.saleOffValue = item.productPrice;
      }

      item.discountCustomerPercent = item.discountCustomerPercent || 0;
      item.saleOffMoney =
        item.saleOffType == "PERCENT"
          ? (item.saleOffValue / 100) * item.productPrice
          : item.saleOffValue;
      item.surchargeMoney =
        item.surchargeType == "PERCENT"
          ? (item.surchargeValue / 100) * item.productPrice
          : item.surchargeValue;

      item.discountCustomerMoney =
        item.discountCustomerPercent * 0.01 * item.productPrice;
      item.totalDiscount = item.saleOffMoney + item.discountCustomerMoney;
      item.priceAfter =
        item.productPrice - item.totalDiscount + item.surchargeMoney;

      item.rawQuantity = item.productQuantity;
      item.cashSaleOff = Math.round(item.rawQuantity * item.saleOffMoney);

      item.discountCustomer = Math.round(
        item.rawQuantity * item.discountCustomerMoney
      );
      item.surcharge = Math.round(item.rawQuantity * item.surchargeMoney);
      item.costTotal = Math.round(item.rawQuantity * item.costPrice);

      item.total = Math.round(item.productPrice * item.rawQuantity);

      item.totalPayment =
        item.total - item.cashSaleOff - item.discountCustomer + item.surcharge;

      order.beforeSaleOffTotal += item.total;
      order.saleOffTotal += item.cashSaleOff;
      order.discountCustomerTotal += item.discountCustomer;
      order.surchargeProductTotal += item.surcharge;
      order.costTotal += item.costTotal;

      total += item.totalPayment;
      order.totalQuantity += item.rawQuantity;

      if (item.isGift) {
        order.totalGiftQuantity += item.rawQuantity;
      } else {
        order.totalProductQuantity += item.rawQuantity;
      }

      if (item.isBundle) {
        item.children.forEach((child) => {
          child.rawQuantity = child.bundleQuantity * item.rawQuantity;
          child.productQuantity = child.rawQuantity;
          child.costTotal = child.rawQuantity * child.costPrice;
          order.costTotal += child.costTotal;
        });
      }
      console.log("item.saleOffValue", item.saleOffValue);
    });

  order.invoiceInitials = order.invoiceInitials.filter(
    (i) => i.productQuantity > 0
  );
  order.totalQuantity = Math.round(order.totalQuantity * 100) / 100;
  order.total = total;

  order.totalDiscountInvoice = 0;

  if (order.isMemberDiscount) {
    order.membershipDiscountPercent = order.customer.discountPercent || 0;
    order.membershipDiscountMoney =
      (order.total * order.membershipDiscountPercent) / 100;
  } else {
    order.membershipDiscountPercent = 0;
    order.membershipDiscountMoney = 0;
  }
  order.totalDiscountInvoice += order.membershipDiscountMoney;
  order.numberPointConvert = order.numberPointConvert || 0;
  order.pointToMoney = order.pointToMoney || 1;

  order.moneyPointConvert = Math.round(
    order.numberPointConvert * order.pointToMoney
  );

  if (
    Math.abs(order.moneyPointConvert) >
    Math.abs(total - order.membershipDiscountMoney)
  ) {
    order.moneyPointConvert = total - order.membershipDiscountMoney;
    order.numberPointConvert = Math.round(
      order.moneyPointConvert / order.pointToMoney
    );
  }
  order.totalDiscountInvoice += order.moneyPointConvert;

  order.memberShipAmount =
    order.moneyPointConvert + order.membershipDiscountMoney;

  const totalAfterMember = total - order.totalDiscountInvoice;

  order.voucherDiscount = 0;
  order.couponDiscount = 0;
  // order.cashDiscount = 0;
  order.cashDiscountPercent =
    Math.round((order.cashDiscountPercent || 0) * 100) / 100;

  if (order.invoiceDiscounts) {
    order.invoiceDiscounts
      .filter((i) => !i.isDelete)
      .map((item) => {
        if (item.discountType === "PERCENT") {
          item.discountMoney = (item.discountValue * totalAfterMember) / 100;
          order.couponDiscount += item.discountMoney;
        } else {
          item.discountMoney = item.discountValue;
          order.voucherDiscount += item.discountMoney;
        }
      });
  }
  if (Math.abs(order.voucherDiscount) > Math.abs(totalAfterMember)) {
    order.voucherDiscount = totalAfterMember;
  }
  if (Math.abs(order.couponDiscount) > Math.abs(totalAfterMember)) {
    order.couponDiscount = totalAfterMember;
  }

  order.totalDiscountInvoice += order.couponDiscount;
  order.totalDiscountInvoice += order.voucherDiscount;

  const totalAfterDiscount =
    totalAfterMember - order.voucherDiscount - order.couponDiscount;
  order.cashDiscountType = order.cashDiscountType || "MONEY";
  order.cashDiscount = order.cashDiscount || 0;
  order.cashDiscountPercent = order.cashDiscountPercent || 0;

  if (order.cashDiscountType == "MONEY") {
    order.cashDiscountPercent = (order.cashDiscount * 100) / totalAfterDiscount;
    order.cashDiscountPercent =
      Math.round((order.cashDiscountPercent || 0) * 100) / 100;
  } else {
    if (order.cashDiscountPercent > 100) {
      order.cashDiscountPercent = 100;
    }
    order.cashDiscount = totalAfterDiscount * order.cashDiscountPercent * 0.01;
  }

  if (Math.abs(order.cashDiscount) > Math.abs(totalAfterDiscount)) {
    if (order.cashDiscountType == "MONEY") {
      order.cashDiscount = totalAfterMember - order.voucherDiscount;
      order.cashDiscountPercent =
        (order.cashDiscount * 100) / totalAfterDiscount;
    }
  }

  order.cashDiscount = Math.round(order.cashDiscount);
  order.totalDiscountInvoice += order.cashDiscount;
  order.surcharges = order.surcharges || [];
  order.surcharge = order.surcharges
    .filter((i) => !i.isDelete)
    .reduce((a, b) => a + b.amount, 0);

  order.totalSurcharge = order.surcharge + order.surchargeProductTotal;

  order.totalDiscount =
    order.totalDiscountInvoice +
    order.saleOffTotal +
    order.discountCustomerTotal;

  order.totalDiscountProduct = order.saleOffTotal + order.discountCustomerTotal;

  order.invoiceInitials = order.invoiceInitials.map((item) => {
    if (order.beforeSaleOffTotal) {
      item.percent = item.total / order.beforeSaleOffTotal;
    } else {
      item.percent = item.rawQuantity / order.totalQuantity;
    }

    item.orderSaleOff = Math.round(item.percent * order.totalDiscountInvoice);
    item.orderSaleOffItem = item.orderSaleOff / item.rawQuantity;

    item.orderSurcharge = Math.round(item.percent * order.surcharge);
    item.orderSurchargeItem = item.orderSurcharge / item.rawQuantity;

    item.totalBeforeTax =
      item.totalPayment - item.orderSaleOff + item.orderSurcharge;

    if (order.sameTax) {
      item.percentTax = order.percentTax;
    }

    item.taxMoney = item.totalBeforeTax * item.percentTax * 0.01;
    item.totalAfterTax = item.totalBeforeTax + item.taxMoney;

    item.priceItem =
      Math.round((100 * item.totalBeforeTax) / item.rawQuantity) / 100;
    order.taxMoney += item.taxMoney;
    order.totalAfterTaxProduct += item.totalAfterTax;

    return item;
  });

  order.grandTotalBeforeTax =
    order.beforeSaleOffTotal - order.totalDiscount + order.totalSurcharge;

  order.grandTotal = order.grandTotalBeforeTax + order.taxMoney;
  order.invoiceCardPayments = order.invoiceCardPayments || [];
  order.invoiceCashPayments = order.invoiceCashPayments || [];

  order.invoiceCashPayments = order.invoiceCashPayments.filter(
    (i) => i.totalPayment
  );
  order.invoiceCardPayments = order.invoiceCardPayments.filter(
    (i) => i.totalPayment
  );

  order.extraCash = order.invoiceCashPayments
    .filter((a) => a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);

  order.extraCard = order.invoiceCardPayments
    .filter((a) => a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);

  order.extraMoney = order.extraCash + order.extraCard;

  order.cashTotal = order.invoiceCashPayments
    .filter((a) => !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);
  order.cardTotal = order.invoiceCardPayments
    .filter((a) => !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);

  order.cashTotalMoney = order.invoiceCashPayments
    .filter((a) => a.totalPayment > 0 && !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);
  order.cashTotalReturnMoney =
    -1 *
    order.invoiceCashPayments
      .filter((a) => a.totalPayment < 0)
      .reduce((a, b) => a + b.totalPayment, 0);

  order.cardTotalMoney = order.invoiceCardPayments
    .filter((a) => a.totalPayment > 0 && !a.isExtra)
    .reduce((a, b) => a + b.totalPayment, 0);
  order.cardTotalReturnMoney =
    -1 *
    order.invoiceCardPayments
      .filter((a) => a.totalPayment < 0)
      .reduce((a, b) => a + b.totalPayment, 0);

  order.totalMoney = order.cashTotalMoney + order.cardTotalMoney;
  order.totalMoneyReturn =
    order.cashTotalReturnMoney + order.cardTotalReturnMoney - order.extraMoney;

  order.remainPayment = order.grandTotal - (order.cardTotal + order.cashTotal);
  order.paidMoney = order.cardTotal + order.cashTotal;

  order.needRefundPayment = 0;
  if (order.remainPayment <= 0) {
    order.needRefundPayment = order.remainPayment * -1;
    order.remainPayment = 0;
    order.paymentStatus = "PAID";
  } else if (order.remainPayment == order.grandTotal) {
    order.paymentStatus = "UNPAID";
  } else {
    order.paymentStatus = "PARTIAL_PAID";
  }

  order.employeeSales = (order.employeeSales || []).map((i) => {
    return {
      id: i.id,
      isDelete: i.isDelete,
      employeeId: i.employeeId,
      employeeName: i.employeeName,
      percentSale: i.percentSale,
      moneySale: i.percentSale * order.grandTotalBeforeTax * 0.01,
    };
  });

  order.invoiceProcessSteps = order.invoiceProcessSteps || [];
  order.invoiceProcessSteps = (order.invoiceProcessSteps || []).map(
    (process) => {
      return countPriceProcess(process, order);
    }
  );
  order.totalDebt = _.sumBy(
    order.invoiceProcessSteps.filter((a) => a.isDebt),
    "grandTotal"
  );

  order.remainDebt = order.totalDebt - order.paidMoney;

  var channel = Vue.prototype.$channels.find(
    (a) => a.name == order.channel
  ) || { isOnline: false };
  order.isOnline = channel.isOnline;

  const totalProductNeed = _.sumBy(order.invoiceInitials, "productQuantity");
  const totalProductStock = _.sumBy(
    _.flatMap(
      order.invoiceProcessSteps.filter(
        (a) => a.doneStock && a.processStatus == "DONE"
      ),
      "products"
    ),
    "productQuantity"
  );

  if (totalProductStock < totalProductNeed) {
    order.stockStatus = "PROCESSING";
  }

  if (totalProductStock == 0) {
    order.stockStatus = "UNFINISHED";
  }

  if (totalProductStock == totalProductNeed) {
    order.stockStatus = "FINISHED";
  }

  return order;
};

export const countPriceProcess = (process, order) => {
  process.total = 0;
  process.costTotal = 0;
  process.totalQuantity = 0;
  process.beforeSaleOffTotal = 0;
  process.discountCustomer = 0;
  process.surchargeProductTotal = 0;
  process.saleOffTotal = 0;
  process.taxMoney = 0;

  (process.products || [])
    .filter((a) => !a.isDelete)
    .map((item) => {
      item.quantity = item.quantity || item.productQuantity;

      console.log("item.quantity2", item.quantity);
      if (item.isGift) {
        item.productPrice = 0;
      }
      item.surchargeValue = item.surchargeValue || 0;
      item.saleOffValue = item.saleOffValue || 0;
      item.discountCustomerPercent = item.discountCustomerPercent || 0;
      item.saleOffMoney =
        item.saleOffType == "PERCENT"
          ? (item.saleOffValue / 100) * item.productPrice
          : item.saleOffValue;
      item.surchargeMoney =
        item.surchargeType == "PERCENT"
          ? (item.surchargeValue / 100) * item.productPrice
          : item.surchargeValue;

      item.discountCustomerMoney =
        item.discountCustomerPercent * 0.01 * item.productPrice;
      item.priceAfter =
        item.productPrice -
        item.saleOffMoney -
        item.discountCustomerMoney +
        item.surchargeMoney;

      item.cashSaleOff = Math.round(item.quantity * item.saleOffMoney);
      item.discountCustomer = Math.round(
        item.quantity * item.discountCustomerMoney
      );
      item.surcharge = Math.round(item.quantity * item.surchargeMoney);
      item.costTotal = Math.round(item.quantity * item.costPrice);
      item.total = Math.round(item.productPrice * item.quantity);

      item.totalPayment =
        item.total - item.cashSaleOff - item.discountCustomer + item.surcharge;

      process.surchargeProductTotal += item.surcharge;
      process.discountCustomer += item.discountCustomer;
      process.total += item.totalPayment;
      process.costTotal += item.costTotal;
      process.totalQuantity += item.quantity;
      process.beforeSaleOffTotal += item.total;
      process.saleOffTotal += item.cashSaleOff;
    });

  if (order.total == 0) {
    process.percentDone = process.totalQuantity / order.totalQuantity;
  } else {
    process.percentDone = process.total / order.total;
  }
  process.percentDone = Math.abs(process.percentDone)

  process.cashDiscount = Math.round(order.cashDiscount * process.percentDone);

  process.voucherDiscount = Math.round(
    order.voucherDiscount * process.percentDone
  );

  process.couponDiscount = Math.round(
    order.couponDiscount * process.percentDone
  );

  process.moneyPointConvert = Math.round(
    order.moneyPointConvert * process.percentDone
  );

  process.membershipDiscountMoney = Math.round(
    order.membershipDiscountMoney * process.percentDone
  );

  process.surchargeProcess = (process.surcharges || [])
    .filter((a) => !a.isDelete)
    .reduce((a, b) => a + +b.amount, 0);

  process.surchargeOrder = Math.round(order.surcharge * process.percentDone);
  process.surcharge = process.surchargeProcess + process.surchargeOrder;

  process.totalDiscount =
    process.cashDiscount +
    process.voucherDiscount +
    process.couponDiscount +
    process.membershipDiscountMoney +
    process.moneyPointConvert;
  process.products = (process.products || []).map((item) => {
    if (order.beforeSaleOffTotal) {
      item.percent = item.total / order.beforeSaleOffTotal;
    } else {
      item.percent = item.quantity / process.totalQuantity;
    }

    item.orderSaleOff = Math.round(item.percent * process.totalDiscount);
    item.orderSaleOffItem = item.orderSaleOff / item.quantity;

    item.orderSurcharge = Math.round(item.percent * process.surcharge);
    item.orderSurchargeItem = item.orderSurcharge / item.quantity;

    item.totalBeforeTax =
      item.totalPayment - item.orderSaleOff + item.orderSurcharge;

    item.taxMoney = Math.round(item.totalBeforeTax * item.percentTax * 0.01);

    process.taxMoney += item.taxMoney;

    item.totalAfterTax = item.totalBeforeTax + item.taxMoney;

    item.priceItem = item.totalBeforeTax / item.quantity;
    return item;
  });

  process.grandTotalBeforeTax = Math.round(
    process.total - process.totalDiscount + process.surcharge
  );

  process.grandTotal = Math.round(
    process.taxMoney + process.grandTotalBeforeTax
  );

  return process;
};

export const refundPayment = async (selected, _this, cb) => {
  var res = await _this.$api.getInvoicesSummary(
    { branchId: _this.$branchId, id_in: selected },
    selected.length,
    0,
    "id_desc"
  );
  var orders = res.list.map((a) => {
    a.cardTotal - a.cashTotal > 0;
    return a;
  });
  if (!orders.length) {
    _this.$noti.open("Những hoá đơn bạn chọn đã hoàn đủ tiền", true);
    return;
  }
  var total = orders.reduce((a, b) => a + b.cardTotal + b.cashTotal, 0);

  var accountantCash = _this.$financeAuto.find(
    (i) => i.keyPayment == "REFUND_CASH"
  );

  const inputs = [
    {
      title: `Số tiền hoàn trả (max: ${_this.$formatMoney(total)})`,
      key: "total",
      required: true,
      value: total,
      width: "40%",
      type: "price",
      disabled: orders.length != 1,
      max: total,
    },
    {
      title: "Hoàn tiền qua",
      key: "paymentType",
      required: true,
      width: "60%",
      type: "select",
      value: "CASH",
      selectList: _this.$paymentTypes,
    },
    {
      title: "Người nộp",
      key: "transferRepresent",
      required: true,
      width: "50%",
      value: _this.$user.fullName,
    },

    {
      title: "Người nhận",
      key: "receiverRepresent",
      required: true,
      width: "50%",
      value:
        (orders.length > 0 &&
          orders[0].customers &&
          orders[0].customers.customerName) ||
        "Khách lẻ",
    },

    {
      title: "Thông tin - địa chỉ người nộp",
      key: "address",
      width: "100%",
      value: _this.$user.fullName,
    },
    {
      title: "Ghi chú",
      key: "des",
      width: "100%",
      type: "textarea",
      value: `Hoàn tiền cho đơn hàng ${orders.map((i) => i.code).join(", ")}`,
    },
    {
      title: "Tài khoản kế toán",
      type: "title",
    },
    {
      title: "Yếu tố chi",
      key: "elementCode",
      width: "50%",
      type: _this.$financeCodes.filter((a) => a.accountantType == "EXPENSE")
        .length
        ? "select"
        : "text",
      selectList: _this.$financeCodes.filter(
        (a) => a.accountantType == "EXPENSE"
      ),
    },

    {
      title: "Quyển số",
      key: "bookNo",
      width: "25%",
    },

    {
      title: "Số phiếu",
      key: "codeNumber",
      width: "25%",
    },
    {
      title: "Mã ĐTPN nộp (có)",
      key: "transferCode",
      width: "50%",
      disabled: !!_this.$user.employeeCode,
      value: _this.$user.employeeCode,
    },

    {
      title: "Mã ĐTPN nhận (nợ)",
      key: "receiverCode",
      width: "50%",
      disabled: true,

      value:
        (orders.length > 0 &&
          orders[0].customers &&
          orders[0].customers.customerCode) ||
        "KHACH_LE",
    },

    {
      title: "ĐTPN nộp (có)",
      key: "transfer",
      width: "50%",
      value: _this.$user.fullName,
    },
    {
      title: "ĐTPN nhận (nợ)",
      key: "receiver",
      width: "50%",

      value:
        (orders.length > 0 &&
          orders[0].customers &&
          orders[0].customers.customerName) ||
        "Khách lẻ",
    },

    {
      title: "Tải khoản có",
      key: "debtAccId",
      width: "50%",
      type: "select",
      selectList: _this.$accountList,
      value: accountantCash && accountantCash.credit.id,
    },
    {
      title: "Tải khoản nợ",
      key: "availableAccId",
      width: "50%",
      selectList: _this.$accountList,
      value: accountantCash && accountantCash.debt.id,
      type: "select",
    },
  ];

  const config = {
    title:
      orders.length == 1
        ? `Hoàn tiền cho đơn ${orders[0].code} - (${_this.$formatMoney(total)})`
        : `Hoàn tiền cho ${orders.length} đơn - (${_this.$formatMoney(total)})`,
  };
  _this.$root.formPopup.openPopup(inputs, config, (res) => {
    var incomeData = [];
    var remain = res.total;
    var invoices = orders.map((i) => {
      const item = {
        id: i.id,
        orderStatus: i.orderStatus,
        paymentStatus: i.paymentStatus,
        remainPayment: 0,
      };
      if (res.paymentType == "CASH") {
        item.invoiceCashPayments = [
          {
            currencyUnit: Vue.prototype.$currency,
            totalCash: remain * -1,
            totalPayment: remain * -1,
            exchangePrice: 1,
          },
        ];
        item.invoiceCardPayments = [];
        item.totalCash = i.cashTotal + remain * -1;
        item.totalCard = i.cardTotal + 0;
      } else {
        item.invoiceCashPayments = [];
        item.invoiceCardPayments = [
          {
            totalPayment: remain * -1,
            cardType: res.paymentType.split("&&")[0],
            cardNumber: res.paymentType.split("&&")[1],
          },
        ];

        item.totalCard = i.cardTotal + remain * -1;
        item.totalCash = i.cashTotal + 0;
      }
      item.paidMoney = item.totalCash + item.totalCard;
      item.remainDebt = i.totalDebt - item.paidMoney;
      item.remainPayment = i.grandTotal - item.paidMoney;

      remain = remain - i.remainPayment;
      if (remain < 0) {
        remain = 0;
      }

      if (item.remainPayment > 0) {
        item.paymentStatus = "PARTIAL_PAID";
      }
      if (item.remainPayment == 0) {
        item.paymentStatus = "PAID";
      }
      if (item.remainPayment == item.grandTotal) {
        item.paymentStatus = "UNPAID";
      }
      return item;
    });

    var paymentReqs = [];
    if (res.debtAccId && res.availableAccId && res.total > 0) {
      paymentReqs.push({
        debtAccId: res.debtAccId,
        availableAccId: res.availableAccId,
        totalPayment: res.total,

        transfer: res.transfer,
        receiver: res.receiver,
        receiverCode: res.receiverCode,
        transferCode: res.transferCode,
      });
    }

    if (paymentReqs && paymentReqs.length) {
      var elment = _this.$financeCodes.find((a) => a.id == res.elementCode);
      incomeData.push({
        bookNo: res.bookNo,
        codeNumber: res.codeNumber,
        elementCode: res.elementCode,
        elementName: elment ? elment.name : res.elementCode,
        totalPayment: res.total,
        transfer: res.transfer,
        transferRepresent: res.transferRepresent,
        receiver: res.receiver,
        receiverRepresent: res.receiverRepresent,
        receiverCode: res.receiverCode,
        transferCode: res.transferCode,

        accountantType: "EXPENSE",
        paymentReqs: paymentReqs,
        address: res.address,
        paymentDetailReqs: {
          currencyUnit: this.$currency,
          originalCurrency: res.total,
          exchangePrice: 1,
          totalPayment: res.total,
          isCashPayment: res.paymentType == "CASH",
        },
        description: res.des,
      });
    }
    _this.$root.formPopup.sending = true;

    _this.$api
      .paymentInvoice(invoices, incomeData, _this.$branchId)
      .then(() => {
        _this.$noti.open(_this.$t("common.payment_success"));
        _this.$root.formPopup.closePopup();

        cb({ invoices, paymentType: res.paymentType });
      })
      .catch(() => {
        _this.$root.formPopup.sending = false;
      });
  });
};

export const getFinanceFields = (viewDetail) => [
  {
    key: "code",
    searchKey: ["code_ilike", "bookNo_ilike", "codeNumber_ilike"],
    className: "nobreak  pointer ",
    title: "Mã phiếu",
    showData: (r) =>
      `<a tabindex="1" class="bold">#${r.code}</a><p class="sub-text m-0">${
        r.isManual ? "" : "Tạo tự động"
      }</p> <p class="sub-text">Quyển số: ${r.bookNo || "-"} | ${
        r.codeNumber || "-"
      }</p>`,

    click: (r) => {
      viewDetail && viewDetail(r);
    },
    showDataRaw: (r) => r.code,
  },
  {
    key: "isManual",
    noShow: () => true,
    showDataRaw: (r) => (r.isManual ? "Tạo tự động" : ""),
    title: "Hình thức tạo",
  },
  {
    key: "codeNumber",
    noShow: () => true,
    title: "Số phiếu",
  },
  {
    key: "bookNo",
    title: "Quyển số",
    noShow: () => true,
  },
  {
    key: "totalPayment",
    title: "Số tiền",
    className: "nobreak text-right",
    searchType: "number",
    showDataRaw: (r) => r.totalPayment,
    showData: (r) => {
      if (r.accountantType == "INCOME") {
        return (
          '<span class="text-success bold">' +
          ' <i class="fa fa-arrow-up " ></i>' +
          Vue.prototype.$formatMoney(r.totalPayment) +
          "</span>"
        );
      }
      if (r.accountantType == "EXPENSE") {
        return (
          '<span class="text-danger bold">' +
          ' <i class="fa fa-arrow-down " ></i> ' +
          Vue.prototype.$formatMoney(r.totalPayment) +
          "</span>"
        );
      }
    },
  },
  {
    key: "receiver",
    searchKey: ["receiver_ilike", "receiverCode_ilike"],
    title: "ĐTPN nhận (nợ)",
    width: "200px",
    showDataRaw: (r) => r.receiver,
    showData: (r) =>
      `<label>${r.receiverCode || ""}</label><p>${r.receiver || ""}</p>`,
  },
  {
    key: "receiverCode",
    noShow: () => true,
    title: "Mã ĐTPN nhận (nợ)",
  },

  {
    key: "transfer",
    searchKey: ["transfer_ilike", "transferCode_ilike"],
    title: "ĐTPN nộp (có)",
    showDataRaw: (r) => r.transfer,
    width: "200px",
    showData: (r) =>
      `<label>${r.transferCode || ""}</label><p>${r.transfer || ""}</p>`,
  },
  {
    key: "transferCode",
    noShow: () => true,
    title: "Mã ĐTPN nộp (có)",
  },

  {
    key: "receiverRepresent",
    title: "Người nhận",
    width: "200px",
    searchKey: "receiverRepresent_like",
  },

  {
    key: "transferRepresent",
    title: "Người nộp",
    width: "200px",
    searchKey: "transferRepresent_like",
  },

  {
    key: "startDate",
    title: "Ngày giao dịch",
    className: "nobreak",
    searchType: "date-range",
    showData: (r) =>
      r.startDate ? Vue.prototype.$formatDate(r.startDate) : "",
  },
  {
    key: "description",
    title: "Diễn giải",
    searchKey: "description_ilike",
    width: "400px",
    showDataRaw: (r) => r.description,
    showData: (r) =>
      r.description && r.description.length > 70
        ? r.description.substring(0, 50) + "[...]"
        : r.description,
  },
  {
    key: "incomeExpenseRefs",
    title: "Tham khảo",
    searchKey: [
      "incomeExpenseRefs.invoices.code_ilike",
      "incomeExpenseRefs.purchaseOrders.code_ilike",
      "incomeExpenseRefs.warehouseReceipts.code_ilike",
      "incomeExpenseRefs.warehouseOutReceipt.code_ilike",
    ],
    className: "bold",
    width: "200px",
    showData: (r) => {
      var out = [];
      if (r.incomeExpenseRefs && r.incomeExpenseRefs.length <= 2) {
        r.incomeExpenseRefs.map((i) => {
          if (i.invoices) {
            out.push(`<span class="nobreak">#${i.invoices.code}</span>`);
          }
          if (i.purchaseOrders) {
            out.push(`<span class="nobreak">#${i.purchaseOrders.code}</span>`);
          }
          if (i.warehouseReceipts) {
            out.push(
              `<span class="nobreak">#${i.warehouseReceipts.code}</span>`
            );
          }
          if (i.warehouseOutReceipt) {
            out.push(
              `<span class="nobreak">#${i.warehouseOutReceipt.code}</span>`
            );
          }
        });
        return out.join(", ");
      } else {
        [r.incomeExpenseRefs[0], r.incomeExpenseRefs[1]].map((i) => {
          if (i.invoices) {
            out.push(`<span class="nobreak">#${i.invoices.code}</span>`);
          }
          if (i.purchaseOrders) {
            out.push(`<span class="nobreak">#${i.purchaseOrders.code}</span>`);
          }
        });
        return (
          out.join(", ") +
          `<span class="tag nobreak">+ ${r.incomeExpenseRefs.length - 2}</span>`
        );
      }
    },
  },
  {
    key: "payment",
    title: "Hạch toán",
    searchKey: [
      "incomeExpensePayments.debtAcc.companyCircularCategory.code_ilike",
      "incomeExpensePayments.availableAcc.companyCircularCategory.code_ilike",
    ],
    noSort: true,
    className: "nobreak",
    showDataRaw: (r) => r.payments.map((i) => i.totalPayment).join("\n"),

    showData: (r) =>
      r.payments
        .map(
          (i) =>
            (r.payments.length > 1
              ? `<b style="width:100px">
              ${Vue.prototype.$formatMoney(i.totalPayment)}
              </b> | `
              : "") +
            `${_.get(i, "debtAcc.detail.code", "")}.${_.get(
              i,
              "debtAcc.detail.name",
              ""
            )} 
              <i class="fa fa-arrow-left"></i> 
              ${_.get(i, "availableAcc.detail.code", "")}.${_.get(
              i,
              "availableAcc.detail.name",
              ""
            )}`
        )
        .join("<br />"),
  },
  {
    key: "debtAcc",
    showDataRaw: (r) =>
      r.payments
        .map((i) => `${i.debtAcc.detail.code}.${i.debtAcc.detail.name}`)
        .join("\n"),
    noShow: () => true,
    title: "Tài khoản nợ",
  },
  {
    key: "availableAcc",
    showDataRaw: (r) =>
      r.payments
        .map(
          (i) => `${i.availableAcc.detail.code}.${i.availableAcc.detail.name}`
        )
        .join("\n"),
    noShow: () => true,
    title: "Tài khoản có",
  },

  {
    key: "incomeExpensePaymentDetails.totalPayment",
    title: "Thanh toán",
    searchType: "number",
    className: "nobreak text-right",
    showData: (r) =>
      r.paymentDetails
        .map((i) => {
          var out =
            (i.isCashPayment
              ? "Tiền mặt: "
              : `${i.cardType}|${i.cardNumber}: `) +
            `<strong>${Vue.prototype.$formatMoney(i.totalPayment)}</strong>`;
          return out;
        })
        .join("<br />"),

    showDataRaw: (r) =>
      r.paymentDetails
        .map((i) => {
          var out =
            (i.isCashPayment
              ? "Tiền mặt: "
              : `${i.cardType}|${i.cardNumber}: `) + i.totalPayment;
          return out;
        })
        .join("\n"),
  },
];
